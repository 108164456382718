import "./src/styles/global.css"
import React from "react"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.min.css"

export const wrapRootElement = ({ element }) => (
  <div>
    {element}
    <ToastContainer />
  </div>
)
