// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-awareness-index-js": () => import("./../../../src/pages/awareness/index.js" /* webpackChunkName: "component---src-pages-awareness-index-js" */),
  "component---src-pages-cabinet-index-js": () => import("./../../../src/pages/cabinet/index.js" /* webpackChunkName: "component---src-pages-cabinet-index-js" */),
  "component---src-pages-contacts-index-js": () => import("./../../../src/pages/contacts/index.js" /* webpackChunkName: "component---src-pages-contacts-index-js" */),
  "component---src-pages-docs-index-js": () => import("./../../../src/pages/docs/index.js" /* webpackChunkName: "component---src-pages-docs-index-js" */),
  "component---src-pages-faq-index-js": () => import("./../../../src/pages/faq/index.js" /* webpackChunkName: "component---src-pages-faq-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-joomla-index-js": () => import("./../../../src/pages/joomla/index.js" /* webpackChunkName: "component---src-pages-joomla-index-js" */),
  "component---src-pages-load-index-js": () => import("./../../../src/pages/load/index.js" /* webpackChunkName: "component---src-pages-load-index-js" */),
  "component---src-pages-pentest-index-js": () => import("./../../../src/pages/pentest/index.js" /* webpackChunkName: "component---src-pages-pentest-index-js" */),
  "component---src-pages-pricing-index-js": () => import("./../../../src/pages/pricing/index.js" /* webpackChunkName: "component---src-pages-pricing-index-js" */),
  "component---src-pages-privacy-index-js": () => import("./../../../src/pages/privacy/index.js" /* webpackChunkName: "component---src-pages-privacy-index-js" */),
  "component---src-pages-ref-index-js": () => import("./../../../src/pages/ref/index.js" /* webpackChunkName: "component---src-pages-ref-index-js" */),
  "component---src-pages-reports-waf-index-js": () => import("./../../../src/pages/reports/waf/index.js" /* webpackChunkName: "component---src-pages-reports-waf-index-js" */),
  "component---src-pages-scoring-index-js": () => import("./../../../src/pages/scoring/index.js" /* webpackChunkName: "component---src-pages-scoring-index-js" */),
  "component---src-pages-scoring-scan-js": () => import("./../../../src/pages/scoring/scan.js" /* webpackChunkName: "component---src-pages-scoring-scan-js" */),
  "component---src-pages-sign-in-index-js": () => import("./../../../src/pages/sign-in/index.js" /* webpackChunkName: "component---src-pages-sign-in-index-js" */),
  "component---src-pages-team-index-js": () => import("./../../../src/pages/team/index.js" /* webpackChunkName: "component---src-pages-team-index-js" */),
  "component---src-pages-terms-index-js": () => import("./../../../src/pages/terms/index.js" /* webpackChunkName: "component---src-pages-terms-index-js" */),
  "component---src-pages-wordpress-index-js": () => import("./../../../src/pages/wordpress/index.js" /* webpackChunkName: "component---src-pages-wordpress-index-js" */),
  "component---src-pages-wordpress-security-audit-index-js": () => import("./../../../src/pages/wordpress-security-audit/index.js" /* webpackChunkName: "component---src-pages-wordpress-security-audit-index-js" */)
}

